export default {
  delete_take: '/take/api/delete',
  restore_take: '/take/api/restore-from-history',
  sync_score: '/take/api/sync-score',
  move_take: '/take/api/move-take-to-other-course',
  restore_ke_hoach_ho_tro_dong_nghiep_search:
    '/master-teacher/support/search-ke-hoach-ho-tro',
  restore_ke_hoach_ho_tro_dong_nghiep_save:
    '/master-teacher/support/save-ke-hoach-ho-tro',
  remove_cot_can_1_flag: '/user/support/remove-cot-can-1-flag',
  update_user_grade: '/user/support/update-user-grade',
};
